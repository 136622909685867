import { DEFAULT_RANGE } from "@/app/config-global";
import { useLocales } from "@/app/locales";
import { PATH_DASHBOARD } from "@/app/routes/paths";
import CustomerService from "@/app/services/api/CustomerService";
import OrderService from "@/app/services/api/OrderService";
import StatService from "@/app/services/api/StatService";
import {
  IApplicationState,
  ICustomer,
  IOrder,
  IProvisioning,
} from "@/app/types";
import { IUserEvolutionStats } from "@/app/types/StatTypes";
import { CustomAvatar } from "@/core/components/custom-avatar";
import Iconify from "@/core/components/iconify";
import Image from "@/core/components/image";
import Label from "@/core/components/label";
import Scrollbar from "@/core/components/scrollbar/Scrollbar";
import useCurrency from "@/core/hooks/useCurrency";
import useFilters from "@/core/hooks/useFilters";
import useToast from "@/core/hooks/useToast";
import useTranslation from "@/core/hooks/useTranslation";
import buildValidators from "@/core/utils/buildValidators";
import getFullName, { getValueOrNA } from "@/core/utils/getFullName";
import toSentence from "@/core/utils/toSentence";
import AppModal from "@/presentation/components/AppModal";
import AutoForm from "@/presentation/components/AutoForm";
import TablePage, {
  ICustomBreadcrumbsLink,
} from "@/presentation/components/TablePage";
import { AppAreaInstalled } from "@/presentation/sections/@dashboard/general/app";
import orderEffects from "@/redux/slices/order/effects";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardSkeleton, ViewTypeSelect } from "../StatsPage";
import orderColumns from "./data/columns";
import {
  orderColorByStatus,
  orderTableTopOptions,
  orderToolbarOptions,
} from "./data/constants";
import orderFields from "./data/fields";

export interface IOrdersPageProps {
  additionalFilters?: Record<string, string | undefined>;
  breadcrumbs?: null | ICustomBreadcrumbsLink[];
  noPage?: boolean;
  showEvolution?: boolean;
}

const OrdersPage: React.FC<IOrdersPageProps> = ({
  additionalFilters,
  breadcrumbs,
  noPage,
  showEvolution,
}) => {
  // Store
  const order = useSelector((state: IApplicationState) => state.order);

  // State
  const [orderToShow, setOrderToShow] = React.useState<IOrder | null>(null);
  const [orderToChangeStatus, setOrderToChangeStatus] =
    React.useState<IOrder | null>(null);
  const [orderToChangeStockiste, setOrderToChangeStokiste] =
    React.useState<IOrder | null>(null);
  // Functions
  const closeModal = () => {
    setOrderToShow(null);
  };

  const closeChangeStatusModal = () => {
    setOrderToChangeStatus(null);
  };

  const closeChangeStockisteModal = () => {
    setOrderToChangeStokiste(null);
  };

  // Hooks
  const t = useTranslation();
  const {
    getData,
    handleKeyword,
    handlePayment_type,
    handleStatus,
    handleOrder,
    handleOrder_field,
    handlePage,
    handlePer_page,
    handleSupply,
    supply,
    handleRole_uuid,
    keyword,
    payment_type,
    status,
    order: _order,
    order_field,
    role_uuid,
    user_uuid, // This is passed throw additional filters into CustomerDetails Modal
  } = useFilters({
    effects: orderEffects,
    initialState: {
      page: order.list.current_page,
      per_page: order.list.per_page,
      keyword: "",
      payment_type: "",
      supply: orderTableTopOptions[0].value,
      status: "",
      order: "",
      order_field: "",
      user_uuid: "",
      role_uuid: "",
      ...(additionalFilters ?? {}),
    },
  });
  const currency = useCurrency();

  const statuses = [
    {
      label: "app.all",
      value: "",
    },
    {
      label: "label.PENDING",
      value: "PENDING",
    },
    {
      label: "label.PAID_female",
      value: "PAID",
    },
    {
      label: "label.DELIVERED_female",
      value: "DELIVERED",
    },
    {
      label: "label.FINISHED_female",
      value: "FINISHED",
    },
  ];

  const roles = [
    {
      label: "app.all",
      value: "",
    },
    {
      label: "label.head_office",
      value: "true",
    },
    {
      label: "label.stockist",
      value: "false",
    },
  ];

  return (
    <>
      <TablePage
        model="order"
        noCreate
        noUpdate
        noPage={noPage}
        // ** Breadcrumbs **
        breadcrumbLinks={breadcrumbs}
        // ** Genre **
        female
        // ** After Breadcrumb **
        afterBreadCrumbComponent={
          showEvolution ? <OrdersEvolution user_uuid={user_uuid} /> : null
        }
        // ** Toolbar **
        tableTopOptions={
          showEvolution ? [orderTableTopOptions[0]] : orderTableTopOptions
        }
        toolbarOptionChange={handleSupply}
        currentToolbarOption={supply}
        toolbarOptions={orderToolbarOptions(
          statuses,
          roles,
          {
            value: keyword,
            onChange: handleKeyword,
          },
          {
            value: status,
            onChange: handleStatus,
          },
          {
            value: role_uuid,
            onChange: handleRole_uuid,
          }
        )}
        // ** On Select rows **
        idProperty="uuid"
        // ** Table CRUD **
        fields={orderFields(t)}
        stateData={order}
        columns={orderColumns(currency)}
        effects={orderEffects}
        getData={getData}
        // ** Filter options **
        isFiltered={(data) =>
          keyword !== "" ||
          payment_type !== "" ||
          status !== "" ||
          supply !== ""
        }
        onReset={() => {
          handleKeyword("");
          handlePayment_type("");
          handleStatus("");
          handleSupply("");
        }}
        // ** Sorting **
        handleFilterOrder={handleOrder}
        handleFilterOrderField={handleOrder_field}
        order={_order}
        orderField={order_field}
        // ** Pagination **
        onChangePage={handlePage}
        onChangeRowsPerPage={handlePer_page}
        noCheckBoxes
        onShowDetails={(row) => setOrderToShow(row)}
        customRowActions={[
          {
            onClick: (row: IOrder) => setOrderToChangeStatus(row),
            icon: "mdi:list-status",
            label: "app.change_status",
          },
          {
            onClick: (row: IOrder) => setOrderToChangeStokiste(row),
            icon: "mdi:list-status",
            label: "app.change_stockiste",
          },
        ]}
      />

      {orderToShow && (
        <UserDetailsModal
          closeModal={closeModal}
          orderToShow={orderToShow}
        />
      )}

      {orderToChangeStatus && (
        <ChangeStatusModal
          closeModal={closeChangeStatusModal}
          orderToChangeStatus={orderToChangeStatus}
        />
      )}
      {orderToChangeStockiste && (
        <ChangeStockisteModal
          closeModal={closeChangeStockisteModal}
          orderToChangeStockiste={orderToChangeStockiste}
        />
      )}
    </>
  );
};

export interface IChangeStatusModalProps {
  orderToChangeStatus: IOrder;
  closeModal: () => void;
}

const ChangeStatusModal: React.FC<IChangeStatusModalProps> = ({
  orderToChangeStatus,
  closeModal,
}) => {
  // State
  const [loading, setLoading] = React.useState<boolean>(false);

  // Hooks
  const t = useTranslation();
  const v = buildValidators(t);
  const toast = useToast();
  const dispatch = useDispatch();

  // Constants
  const options = ["PAID", "DELIVERED", "DELIVEREDVIEW", "FINISHED"].map(
    (s) => ({
      label: t(`label.${s}`),
      value: s,
    })
  );

  // Refs
  const btnRef = React.useRef<HTMLButtonElement>(null);

  // Functions
  const changeStatus = (values: any) => {
    setLoading(true);
    OrderService.changeStatus({
      uuid: orderToChangeStatus.uuid,
      status: values.status,
    })

      .then(async (res) => {
        const data = await res.json();
        if ([200, 201].includes(res.status)) {
          dispatch(
            orderEffects.paginate({
              page: DEFAULT_RANGE.page,
              per_page: DEFAULT_RANGE.per_page,
            })
          );
          closeModal();
          return data;
        } else {
          if (typeof data.detail === "string") {
            toast.error(data.detail);
          } else {
            throw new Error();
          }
        }
      })
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AppModal
      onClose={closeModal}
      maxWidth="sm"
      hideClose={loading}
      actions={[
        <LoadingButton
          key={`save-btn`}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => btnRef.current?.click()}
          startIcon={<Iconify icon="eva:checkmark-fill" />}
        >
          {t(`app.confirm`)}
        </LoadingButton>,
        <Button
          key={`change-status-cancel`}
          disabled={loading}
          variant="contained"
          color="error"
          onClick={closeModal}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          {t("app.cancel")}
        </Button>,
      ]}
      breadCrumbLinks={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: `order.root_plural` },
        { name: orderToChangeStatus?.ref ?? "" },
      ]}
    >
      <Typography
        gutterBottom
        variant="h4"
        sx={{ mt: 4 }}
      >
        {t("app.change_status")}
      </Typography>
      <AutoForm
        initialItem={orderToChangeStatus}
        hideButton
        ref={btnRef}
        fields={{
          status: {
            // initialValue: (row: IOrder) => row?.status,
            initialValue: "",
            type: "select",
            getOptions: () => options,
            getOptionLabel: (option) => option?.label,
            getOptionValue: (option) => option?.value,
            label: "label.status",
            placeholder: "placeholder.status",
            title: "title.status",
            props: {},
            validation: v.string,
          },
        }}
        onSubmit={changeStatus}
      />
    </AppModal>
  );
};

export interface IChangeStockisteModalProps {
  orderToChangeStockiste: IOrder;
  closeModal: () => void;
}
const ChangeStockisteModal: React.FC<IChangeStockisteModalProps> = ({
  orderToChangeStockiste,
  closeModal,
}) => {
  // State
  const [loading, setLoading] = React.useState<boolean>(false);

  // Hooks
  const t = useTranslation();
  const v = buildValidators(t);
  const toast = useToast();
  const dispatch = useDispatch();

  // Refs
  const btnRef = React.useRef<HTMLButtonElement>(null);

  // Functions
  const changeStatus = (values: any) => {
    setLoading(true);
    OrderService.changeStatus({
      uuid: orderToChangeStockiste.uuid,
      stockist_uuid: values.stockist_uuid,
    })

      .then(async (res) => {
        const data = await res.json();
        if ([200, 201].includes(res.status)) {
          dispatch(
            orderEffects.paginate({
              page: DEFAULT_RANGE.page,
              per_page: DEFAULT_RANGE.per_page,
            })
          );
          closeModal();
          return data;
        } else {
          if (typeof data.detail === "string") {
            toast.error(data.detail);
          } else {
            throw new Error();
          }
        }
      })
      .catch(() => {
        toast.error(t("error.unexpected_error_occurred"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AppModal
      onClose={closeModal}
      maxWidth="sm"
      hideClose={loading}
      actions={[
        <LoadingButton
          key={`save-btn`}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => btnRef.current?.click()}
          startIcon={<Iconify icon="eva:checkmark-fill" />}
        >
          {t(`app.confirm`)}
        </LoadingButton>,
        <Button
          key={`change-status-cancel`}
          disabled={loading}
          variant="contained"
          color="error"
          onClick={closeModal}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          {t("app.cancel")}
        </Button>,
      ]}
      breadCrumbLinks={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: `order.root_plural` },
        { name: orderToChangeStockiste?.ref ?? "" },
      ]}
    >
      <Typography
        gutterBottom
        variant="h4"
        sx={{ mt: 4 }}
      >
        {t("app.change_stockiste")}
      </Typography>
      <AutoForm
        initialItem={orderToChangeStockiste}
        hideButton
        ref={btnRef}
        fields={{
          stockist_uuid: {
            type: "autocomplete",
            initialOptions: (row) => row?.stockist,
            getOptions: (_initialOption) => [],
            loadOptions: async (inputValue) => {
              const res = await CustomerService.list_stockist({
                ...DEFAULT_RANGE,
                keyword: inputValue,
                filter: "stockist",
              });
              const data = await res.json();
              return data;
            },
            getOptionLabel: (option: ICustomer) =>
              !option
                ? ""
                : `${getFullName(
                    option?.firstname,
                    option?.lastname
                  )} (${getValueOrNA(option?.address?.country)}, ${getValueOrNA(
                    option?.address?.town
                  )}, ${getValueOrNA(option?.address?.quarter)})`,
            getOptionValue: (option: ICustomer) => option?.uuid,
            initialValue: (row: IProvisioning) => row?.stockist?.uuid,
            label: "label.stockist",
            placeholder: "placeholder.stockist",
            title: "title.stockist",
            props: {},
            validation: v.string,
          },
        }}
        onSubmit={changeStatus}
      />
    </AppModal>
  );
};

interface IUserDetailsModalProps {
  orderToShow: IOrder;
  closeModal: () => void;
}

function UserDetailsModal({ orderToShow, closeModal }: IUserDetailsModalProps) {
  const t = useTranslation();
  const {
    currentLang: { value: lang },
  } = useLocales();
  const toCurrency = useCurrency();
  return (
    <AppModal
      onClose={closeModal}
      maxWidth="md"
      breadCrumbLinks={[
        { name: "dashboard.root", href: PATH_DASHBOARD.root },
        { name: `order.root_plural` },
        { name: orderToShow?.ref ?? "" },
      ]}
    >
      <Stack
        sx={{ py: 3, px: 1 }}
        divider={<Divider component="div" />}
        spacing={2}
      >
        <Stack spacing={1}>
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="h5"
            color="text.primary"
          >
            {toSentence(t("order.root"))}: {orderToShow?.ref}
          </Typography>
          <Stack
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            direction="row"
            spacing={1}
          >
            <Typography
              variant="caption"
              sx={{ color: "text.secondary" }}
            >
              <>
                {t("label.date_added")}:{" "}
                {moment(orderToShow?.date_added).format("LL")}
              </>
            </Typography>
            {/* @ts-ignore */}
            <Label
              // @ts-ignore
              color={(orderColorByStatus as any)[orderToShow?.status ?? ""]}
              title={orderToShow?.status}
              sx={{ color: "text.secondary" }}
            >
              {t(`status.${orderToShow?.status}`)}
              {console.log("les commandes : ", orderToShow)}
            </Label>
          </Stack>
        </Stack>
        <Scrollbar sx={{ maxHeight: "480px" }}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {orderToShow?.order_products?.map((p) => (
              <ListItem
                key={p.uuid}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <Image
                    // @ts-ignore
                    alt={p?.product?.name?.[lang] ?? ""}
                    src={p?.product?.files?.[0]?.url ?? ""}
                    style={{ width: 50, height: 50 }}
                    sx={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                    }}
                  />
                </ListItemAvatar>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <ListItemText
                    // @ts-ignore
                    primary={p?.product?.name?.[lang] ?? ""}
                    primaryTypographyProps={{ mt: 1 }}
                    secondary={
                      <Stack>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {t("label.pu")}:{" "}
                          {toCurrency(
                            (p?.total_price || 0) / (p?.quantity || 1)
                          )}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {t("label.qty")}: {p?.quantity}
                        </Typography>
                      </Stack>
                    }
                  />
                  <Typography
                    fontWeight="bold"
                    variant="body2"
                    sx={{ pt: 1 }}
                  >
                    {toCurrency(p?.total_price ?? 0)}
                  </Typography>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Scrollbar>
        <Grid
          container
          rowSpacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ mt: -0.5 }}
          >
            <Typography
              variant="caption"
              fontWeight="bold"
              sx={{ lineHeight: 0 }}
            >
              {t("label.user")}
            </Typography>
            <Stack
              direction="row"
              gap={2}
              sx={{ mt: 1 }}
            >
              <CustomAvatar
                // @ts-ignore
                src={orderToShow?.user?.avatar?.url}
                name={getFullName(
                  orderToShow?.user?.firstname,
                  orderToShow?.user?.lastname
                )}
              />
              <Stack>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  {getFullName(
                    orderToShow?.user?.firstname,
                    orderToShow?.user?.lastname
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: "text.secondary" }}
                >
                  {orderToShow?.user?.phonenumber}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Stack gap={2}>
              {[
                {
                  title: "label.stockist",
                  items: [
                    getFullName(
                      orderToShow?.stockist?.firstname,
                      orderToShow?.stockist?.lastname
                    ),
                    orderToShow?.stockist?.phonenumber,
                  ],
                },
              ].map((item) => (
                <Stack key={item.title}>
                  <Typography
                    fontWeight="bold"
                    variant="caption"
                  >
                    {t(item.title)}
                  </Typography>
                  <Stack>
                    {item.items.map((el, index) => (
                      <Typography
                        key={`${item.title}${el}${index}`}
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        {el}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography fontWeight="bold">{t("app.payment")}</Typography>
            <Box>
              <Box>
                <Typography>
                  {t(`label.via_${orderToShow?.payment_type}`)}
                </Typography>
              </Box>
              <Typography
                fontWeight="bold"
                sx={{ color: "primary.dark" }}
              >
                {t("app.total")}: {toCurrency(orderToShow?.total_price)}
              </Typography>
            </Box>
          </Grid>
          {/*
<Grid
            item
            xs={12}
            sm={6}
          >
            <Typography fontWeight="bold">{t("app.delivery")}</Typography>
            <Box>
              {[
                orderToShow?.delivery?.address_one,
                orderToShow?.delivery?.town,
                orderToShow?.delivery?.province,
                orderToShow?.delivery?.country,
              ].map((v) => (
                <Typography
                  key={v}
                  sx={{ color: "text.secondary" }}
                  variant={"body2"}
                >
                  {v}
                </Typography>
              ))}
            </Box>
          </Grid>
*/}
        </Grid>
      </Stack>
    </AppModal>
  );
}

function OrdersEvolution({ user_uuid }: { user_uuid?: string }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [stats, setStats] = React.useState<IUserEvolutionStats | null>(null);

  const t = useTranslation();
  const toast = useToast();
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  // Selects state
  const [orderEvolutionSelect, setOrderEvolutionSelect] =
    React.useState<string>("YEARLY");

  // Get data
  const getResourceData = (
    resource: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    callback: (_data: any) => void
  ) => {
    setLoading(true);
    StatService.get(resource)
      .then(async (res) => await res.json())
      .then((data) => callback(data))
      .catch(() => {
        toast.error("error.unexpected_error_occurred");
      })
      .finally(() => setLoading(false));
  };

  // Utils
  const resolveLineChartLabel = (selectedView: string) =>
    selectedView === "YEARLY"
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      : selectedView === "MONTHLY"
      ? Array(new Date(currentYear, new Date().getMonth() + 1, 0).getDate())
          .fill("")
          .map((_, index) => `${index + 1 < 10 ? "0" : ""}${index + 1}`)
      : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  React.useEffect(() => {
    getResourceData(
      `${user_uuid ?? ""}/orders?filter=${orderEvolutionSelect}`,
      setLoading,
      (data) => setStats(data)
    );
  }, [orderEvolutionSelect]);

  return (
    <Box sx={{ mb: 3 }}>
      {loading ? (
        <CardSkeleton height={260} />
      ) : (
        <AppAreaInstalled
          title={toSentence(t("order.order_evolution"))}
          currentYear={currentYear}
          subheader=""
          height={200}
          actions={
            loading ? (
              <CardSkeleton
                width={70}
                height={30}
              />
            ) : (
              <ViewTypeSelect
                value={orderEvolutionSelect}
                onChange={(value) => setOrderEvolutionSelect(value)}
              />
            )
          }
          years={[currentYear]}
          colors={[theme.palette.secondary.main, theme.palette.secondary.main]}
          chart={{
            categories: resolveLineChartLabel(orderEvolutionSelect),
            series: [
              {
                year: currentYear,
                data: [
                  {
                    name: t("label.orders"),
                    data: stats?.orders ?? [],
                  },
                  {
                    name: t("label.supplies"),
                    data: stats?.supplies ?? [],
                  },
                ],
              },
            ],
          }}
        />
      )}
    </Box>
  );
}

export default React.memo(OrdersPage);
